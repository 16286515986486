import { Group } from '@lehman-brothers-holdings/markets'
import mangoStore from '@store/mangoStore'

export default function useMangoGroup(): {
  group: Group | undefined
} {
  const group = mangoStore((s) => s.group)

  return { group }
}
