const KeyboardIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={`${className}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.800871 7.19916H31.1991C31.6344 7.19916 32 7.56477 32 8.00003V24C32 24.4353 31.6344 24.8009 31.1991 24.8009H0.800871C0.365615 24.8009 0 24.4353 0 24V8.00003C0 7.56477 0.365615 7.19916 0.800871 7.19916ZM6.51143 19.1948V21.5974H2.90751V19.1948H6.51143ZM28.7965 19.1948V21.5974H25.1926V19.1948H28.7965ZM22.8945 19.1948V21.5974C18.2111 21.5974 13.5103 21.5974 8.80958 21.5974V19.1948C13.5103 19.1948 18.2111 19.1948 22.8945 19.1948ZM21.7106 14.8074H24.0958V17.1926H21.7106V14.8074ZM26.3939 14.8074H28.7965V17.1926H26.3939V14.8074ZM17.0098 14.8074H19.4124V17.1926H17.0098V14.8074ZM12.309 14.8074H14.7116V17.1926H12.309V14.8074ZM7.60827 14.8074H10.0109V17.1926H7.60827V14.8074ZM26.3939 10.4026H28.7965V12.8053H26.3939V10.4026ZM21.7106 10.4026H24.0958V12.8053H21.7106V10.4026ZM17.0098 10.4026H19.4124V12.8053H17.0098V10.4026ZM12.309 10.4026H14.7116V12.8053H12.309V10.4026ZM7.60827 10.4026H10.0109V12.8053H7.60827V10.4026ZM2.90751 14.8074H5.31012V17.1926H2.90751V14.8074ZM2.90751 10.4026H5.31012V12.8053H2.90751V10.4026Z"
      />
    </svg>
  )
}

export default KeyboardIcon
