const DepositWithdrawIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      className={`${className}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M8.06158 3.00092C7.76869 2.70803 7.29381 2.70803 7.00092 3.00092L3.62592 6.37592C3.33303 6.66881 3.33303 7.14369 3.62592 7.43658C3.91881 7.72947 4.39369 7.72947 4.68658 7.43658L6.78125 5.34191V13.6562C6.78125 14.0705 7.11704 14.4062 7.53125 14.4062C7.94546 14.4062 8.28125 14.0705 8.28125 13.6562V5.34191L10.3759 7.43658C10.6688 7.72947 11.1437 7.72947 11.4366 7.43658C11.7295 7.14369 11.7295 6.66881 11.4366 6.37592L8.06158 3.00092Z" />
      <path d="M17.2188 3.59375C17.2188 3.17954 16.883 2.84375 16.4688 2.84375C16.0545 2.84375 15.7188 3.17954 15.7188 3.59375V11.9081L13.6241 9.81342C13.3312 9.52053 12.8563 9.52053 12.5634 9.81342C12.2705 10.1063 12.2705 10.5812 12.5634 10.8741L15.9384 14.2491C16.2313 14.542 16.7062 14.542 16.9991 14.2491L20.3741 10.8741C20.667 10.5812 20.667 10.1063 20.3741 9.81342C20.0812 9.52053 19.6063 9.52053 19.3134 9.81342L17.2188 11.9081V3.59375Z" />
      <path d="M3.75 15.9688C3.75 15.5545 3.41421 15.2188 3 15.2188C2.58579 15.2188 2.25 15.5545 2.25 15.9688V18.2188C2.25 19.0144 2.56607 19.7775 3.12868 20.3401C3.69129 20.9027 4.45435 21.2188 5.25 21.2188H18.75C19.5457 21.2188 20.3087 20.9027 20.8713 20.3401C21.4339 19.7775 21.75 19.0144 21.75 18.2188V15.9688C21.75 15.5545 21.4142 15.2188 21 15.2188C20.5858 15.2188 20.25 15.5545 20.25 15.9688V18.2188C20.25 18.6166 20.092 18.9981 19.8107 19.2794C19.5294 19.5607 19.1478 19.7188 18.75 19.7188H5.25C4.85218 19.7188 4.47064 19.5607 4.18934 19.2794C3.90804 18.9981 3.75 18.6166 3.75 18.2188V15.9688Z" />
    </svg>
  )
}

export default DepositWithdrawIcon
